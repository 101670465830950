<template>
  <div class="campaign-form">
    <!-- General Information -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <h6 class="mb-0 text-muted py-2">
          Campaign Information
        </h6>
      </div>
      <div class="card-body">
        <!-- Name -->
        <b-form-group
          v-for="locale in locales"
          :key="locale"
        >
          <label>Name</label> <span class="badge badge-dark">{{ locale }}</span>
          <b-form-input
            v-model="internalValue[`name_${locale}`]"
            type="text"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Slug -->
        <b-form-group>
          <label>Slug</label>
          <b-form-input
            v-model="internalValue.slug"
            type="text"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Teaser -->
        <b-form-group
          v-for="locale in locales"
          :key="`teaser_${locale}`"
        >
          <label>Teaser</label> <span class="badge badge-dark">{{ locale }}</span>
          <b-form-textarea
            v-model="internalValue[`teaser_${locale}`]"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Thumbnail -->
        <b-form-group>
          <label>Thumbnail</label>
          <file-selector
            v-model="internalValue.thumbnail"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Checkout Image -->
        <b-form-group>
          <label>Checkout image</label>
          <file-selector
            v-model="internalValue.checkout_image"
            hide-alternate-text
            @input="emitInput"
          />
        </b-form-group>
      </div>
    </div>

    <!-- Marketing Information -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <h6 class="mb-0 text-muted py-2">
          Marketing Information
        </h6>
      </div>
      <div class="card-body">
        <!-- Campaign ID -->
        <b-form-group>
          <label>Campaign ID</label>
          <b-form-input
            v-model="internalValue.marketing_campaign_id"
            type="text"
            maxlength="12"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Product Description -->
        <b-form-group>
          <label>Product Description</label>
          <b-form-input
            v-model="internalValue.marketing_description"
            type="text"
            maxlength="200"
            @input="emitInput"
          />
        </b-form-group>
      </div>
    </div>

    <!-- Publishing Options -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <h6 class="mb-0 text-muted py-2">
          Publishing Options
        </h6>
      </div>
      <div class="card-body">
        <!-- Start Date -->
        <b-form-group>
          <label>Start date</label>
          <b-form-input
            v-model="internalValue.start_date"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <!-- End Date -->
        <b-form-group>
          <label>End date</label>
          <b-form-input
            v-model="internalValue.end_date"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <!-- Private Campaign -->
        <b-form-checkbox
          v-model="internalValue.private"
          switch
        >
          Private Campaign?
        </b-form-checkbox>
      </div>
    </div>

    <!-- One-off Donation Options -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
          <h6 class="mb-0 text-muted">
            <div class="custom-control custom-switch py-2">
              <input
                v-model="internalValue.options.once.enabled"
                type="checkbox"
                class="custom-control-input"
                value="1"
                id="once_enabled"
              >
              <label class="custom-control-label text-muted font-weight-500" for="once_enabled">
                One-off Donation Options
              </label>
            </div>
          </h6>

          <b-button
            :variant="internalValue.options.default === 'once' ? 'success' : 'outline-secondary'"
            size="sm"
            @click="setDefault('once')"
          >
            {{ internalValue.options.default === 'once' ? 'Default' : 'Set Default' }}
          </b-button>
        </div>
      </div>
      <div v-if="internalValue.options.once.enabled" class="card-body">
        <donation-option-form
          v-model="internalValue.options.once"
        />
      </div>
    </div>

    <!-- Monthly Donation Options -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <div class="d-flex justify-content-between">
          <h6 class="mb-0 text-muted">
            <div class="custom-control custom-switch py-2">
              <input
                v-model="internalValue.options.monthly.enabled"
                type="checkbox"
                class="custom-control-input"
                value="1"
                id="monthly_enabled"
              >
              <label class="custom-control-label text-muted font-weight-500" for="monthly_enabled">
                Monthly Donation Options
              </label>
            </div>
          </h6>
          
          <b-button
            :variant="internalValue.options.default === 'monthly' ? 'success' : 'outline-secondary'"
            size="sm"
            @click="setDefault('monthly')"
          >
            {{ internalValue.options.default === 'monthly' ? 'Default' : 'Set Default' }}
          </b-button>
        </div>
      </div>
      <div v-if="internalValue.options.monthly.enabled" class="card-body">
        <donation-option-form
          v-model="internalValue.options.monthly"
        />
      </div>
    </div>

    <!-- Body -->
    <div class="card mb-3">
      <div class="card-header bg-white">
        <h6 class="mb-0 text-muted py-2">
          Content
        </h6>
      </div>
      <div class="card-body">
        <b-form-group
          v-for="locale in locales"
          :key="locale"
        >
          <label>Body</label> <span class="badge badge-dark">{{ locale }}</span>
          <b-form-textarea
            v-model="internalValue[`body_${locale}`]"
            type="text"
            @input="emitInput"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
// import ContentEditor from './ContentEditor.vue'
import DonationOptionForm from './DonationOptionForm.vue'
import FileSelector from './FileSelector.vue'

export default {
  name: 'CampaignForm',

  components: {
    // ContentEditor,
    DonationOptionForm,
    FileSelector
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  computed: {
    locales () {
      return this.$i18n.availableLocales
    }
  },

  data () {
    return {
      internalValue: {
        name_en: '',
        name_th: '',
        slug: '',
        teaser_en: '',
        teaser_th: '',
        thumbnail: null,
        checkout_image: '',
        start_date: '',
        end_date: '',
        private: false,
        options: {
          default: 'once',
          once: {
            enabled: true,
            default: 0,
            custom: {
              amount: 100,
              suffix_th: '',
              suffix_en: ''
            },
            options: []
          },
          monthly: {
            enabled: true,
            default: 0,
            custom: {
              amount: 100,
              suffix_th: '',
              suffix_en: ''
            },
            options: []
          }
        },
        body_en: null,
        body_th: null
      }
    }
  },

  created () {
    if (this.value) {
      const value = JSON.parse(JSON.stringify(this.value))
      if (!value.options.default) {
        value.options.default = 'once'
      }
      this.internalValue = value
      // Object.assign({ thumbnail: null }, this.internalValue)
    }
    this.emitInput()
  },

  methods: {
    emitInput () {
      this.$emit('input', this.internalValue)
    },

    onStartDateChange (ctx) {
      this.internalValue.start_date = ctx.selectedYMD
    },

    setDefault (option) {
      this.internalValue.options.default = option
    }
  }
}
</script>

<style lang="scss" scoped>
.donation-option-item {

  &:nth-child(2n) {
    background-color: #efefef;
  }
}
</style>
