<template>
  <div class="donation-option-form">
    <!-- Minimum Amount -->
    <b-form-group
      class="w-50"
    >
      <label>Minimum Amount</label>
      <b-form-input
        v-model.number="internalValue.custom.amount"
        type="number"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Suffix -->
    <b-form-group
      v-for="locale in locales"
      :key="`suffix_${locale}`"
    >
      <label>Suffix</label> <span class="badge badge-dark">{{ locale }}</span>
      <b-form-input
        v-model="internalValue.custom[`suffix_${locale}`]"
        type="text"
        @input="emitInput"
      />
    </b-form-group>

    <!-- Options -->
    <b-row
      v-for="(option, i) in internalValue.options"
      :key="i"
      class="donation-option-item"
    >
      <b-col md="2">
        <!-- Amount -->
        <b-form-group>
          <label>Amount</label>
          <b-form-input
            v-model.number="internalValue.options[i].amount"
            type="number"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Default -->
        <b-button
          :variant="i !== internalValue.default ? 'outline-secondary' : 'success'"
          size="sm"
          class="mb-3"
          @click="setDefault(i)"
        >
          {{ i !== internalValue.default ? 'Set Default' : 'Default' }}
        </b-button>

        <b-button
          variant="danger"
          size="sm"
          @click.prevent="removeOption(i)"
        >
          <b-icon-x />
          Remove
        </b-button>
      </b-col>
      <b-col md="6">
        <!-- Description -->
        <b-form-group
          v-for="locale in locales"
          :key="`description_${locale}`"
        >
          <label>Description</label> <span class="badge badge-dark">{{ locale }}</span>
          <b-form-input
            v-model="internalValue.options[i][`description_${locale}`]"
            type="text"
            @input="emitInput"
          />
        </b-form-group>

        <!-- Suffix -->
        <b-form-group
          v-for="locale in locales"
          :key="`suffix_${locale}`"
        >
          <label>Suffix</label> <span class="badge badge-dark">{{ locale }}</span>
          <b-form-input
            v-model="internalValue.options[i][`suffix_${locale}`]"
            type="text"
            @input="emitInput"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <!-- Description -->
        <b-form-group>
          <label>Image</label>
          <file-selector
            v-model="internalValue.options[i].image"
            :columns="1"
            @input="emitInput"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-button
      @click.prevent="addOption()"
      variant="primary"
      size="sm"
    >
      <b-icon-plus />
      Add Option
    </b-button>
  </div>
</template>

<script>
import {
  BIconPlus,
  BIconX
} from 'bootstrap-vue'
import FileSelector from './FileSelector.vue'

export default {
  name: 'DonationOptionForm',

  components: {
    BIconPlus,
    BIconX,
    FileSelector
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      internalValue: {
        enabled: true,
        default: 0,
        custom: {
          amount: 100,
          suffix_th: '',
          suffix_en: ''
        },
        options: []
      }
    }
  },

  created () {
    if (this.value) {
      const value = JSON.parse(JSON.stringify(this.value))
      if (!value.default) {
        value.default = 0
      }
      this.internalValue = value
    }
    this.emitInput()
  },

  mounted () {
    if (this.internalValue.options.length === 0) {
      this.addOption()
    }
  },

  methods: {
    addOption () {
      this.internalValue.options.push({
        amount: 100,
        description_th: '',
        description_en: '',
        suffix_th: '',
        suffix_en: '',
        image: {
          path: '',
          alt_th: '',
          alt_en: ''
        }
      })
    },

    removeOption (index) {
      this.internalValue.options.splice(index, 1)
    },

    setDefault (index) {
      this.internalValue.default = index
    },

    emitInput () {
      this.$emit('input', this.internalValue)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
